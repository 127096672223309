<template>
  <div>
    <div
      class="mb-4 flex w-full flex-wrap justify-between gap-2"
    >
      <BaseInputSearch v-model="search" />
      <BasePagination
        v-model="page"
        :page-count="pageCount"
      />
    </div>
    <div
      class="mb-4 flex w-full flex-wrap justify-between gap-2"
    >
      <BaseCountSelect v-model="paginationCount" />

      <button
        type="button"
        class="text-primary border-1 border-primary relative rounded-lg px-2 py-1"
        :disable="isFetching"
        @click="() => refetch()"
      >
        refresh
        <template v-if="isFetching">
          <BaseContainerLoading spinner-class="h-4 w-4 " />
        </template>
      </button>
    </div>
    <BaseTable
      key-field="id"
      :rows="rows"
      :columns="columns"
      :is-loading="isFetching"
    >
      <template #body-cell:status="{ value }">
        <CameraEventStatusChip :status="value" />
      </template>

      <template #body-cell:eventTimestamp="{ value }">
        <BaseTableDatetimeCell :value="value" />
      </template>

      <template #body-cell:vehicleRegistration="{ value }">
        <p class="text-md font-bold uppercase">
          {{ value }}
        </p>
      </template>
    </BaseTable>
  </div>
</template>

<script setup lang="ts">
import type { ColumnDefinition } from "@/types/table"
import type {
  CameraEvent,
  CameraEventFilters,
} from "@/services/cameraEvents/listCameraEvents"

const props = defineProps<{
  filters: Omit<CameraEventFilters, "search">
}>()

const columns: ColumnDefinition<CameraEvent>[] = [
  {
    label: "Timestamp",
    name: "eventTimestamp",
    getValue: (row) => row.eventDatetime,
    autoWidth: true,
  },
  {
    label: "Vehicle Registration",
    name: "vehicleRegistration",
    getValue: (row) => row.vehicleRegistration,
    autoWidth: true,
  },
  {
    label: "Guest Name",
    name: "guestName",
    getValue: (row) => row.guestName || "-",
    autoWidth: true,
  },
  {
    label: "Pitch/Unit",
    name: "pitchInfo",
    getValue: (row) => row.pitchInfo || "-",
    autoWidth: true,
  },
  {
    label: "Status",
    name: "status",
    getValue: (row) => row.status,
    autoWidth: true,
  },
  {
    label: "Reason",
    name: "reason",
    getValue: (row) => row.reasonLabel,
    autoWidth: true,
  },
  {
    label: "Camera",
    name: "cameraName",
    getValue: (row) => row.cameraName,
    autoWidth: true,
  },
  {
    label: "",
    name: "",
    getValue: () => "",
  },
]

const paginationCount = ref(10)
const { pagination, page, pageCount, resetPagination } =
  usePagination({
    limit: paginationCount,
    count: computed(() => data.value?.count || 0),
  })

watch(
  () => props.filters,
  () => resetPagination(),
  { deep: true, immediate: true }
)

const search = ref("")

const { data, isFetching, refetch } = useCameraEvents(
  reactiveComputed(() => ({
    ...props.filters,
    ...pagination.value,
    search: search.value,
  }))
)
const rows = computed<Array<CameraEvent>>(
  () => data.value?.items ?? []
)
</script>
