import { api } from "@//api"
import { useQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"

export const CAMERA_EVENT_QUERY_KEY = "cameraEvents"
export const CAMERA_EVENT_STATUS_MAP = {
  authorized: {
    label: "Authorized",
    description: "",
    color: "green",
  },
  unknown: {
    label: "Unknown",
    description: "",
    color: "amber",
  },
  denied: {
    label: "Denied",
    description: "",
    color: "red",
  },
  exit: {
    label: "Exit",
    description: "",
    color: "blue",
  },
} as const

export type CameraEventStatus =
  keyof typeof CAMERA_EVENT_STATUS_MAP

export const CameraEventStatusOptions = Object.entries(
  CAMERA_EVENT_STATUS_MAP
).map(([value, details]) => ({
  ...details,
  value: value,
}))

export type CameraEvent = {
  id: string
  vehicleRegistration: string
  eventDatetime: Date | undefined
  cameraName: string
  reasonLabel: string
  reason: string
  status: CameraEventStatus
  guestName: string | null
  pitchInfo: string | null
}

export type CameraEventFilters = Partial<{
  siteId: string | null
  fromDt: Date
  untilDt: Date
  search: string
  bookingId: string
  status: CameraEventStatus | null
  vehicleRegistration: string
}>

type CameraEventQuery = CameraEventFilters & PaginationQuery

export const listCameraEvents = async (
  query: CameraEventQuery
) => {
  const { body } = await api.get<
    Paginate<
      RecursivelyConvertDatesToStrings<CameraEvent[]>
    >
  >("/camera/events", {
    query,
  })

  return {
    ...body,
    items: body.items.map((cameraEvent) => ({
      ...cameraEvent,
      eventDatetime: cameraEvent.eventDatetime
        ? DateFns.parseISO(cameraEvent.eventDatetime)
        : undefined,
    })),
  }
}

export const useCameraEvents = (
  query: MaybeRef<CameraEventQuery>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useQuery({
    queryKey: [
      CAMERA_EVENT_QUERY_KEY,
      computed(() => ({
        ...unref(query),
        siteId: currentSiteId.value,
      })),
    ] as const,
    queryFn: ({ queryKey: [_, query_] }) =>
      listCameraEvents(unref(query_)),
    refetchIntervalInBackground: false,
    refetchInterval: 60 * 1000,
  })
}
